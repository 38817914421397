<template>
  <div class="m-1">
    <b-row>
      <b-col
        cols="4"
      >
        <b-form-group
          label="Selecione um intervalo"
          label-for="medication"
        >
          <validation-provider
            #default="{ errors }"
            name="intervalo"
            rules="required"
          >

            <v-select
              v-model="selected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="intervalOptions"
              label="name"
              @option:selected="nextTimes(true)|nextAmount(true)|save()"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

        </b-form-group>
      </b-col>
      <b-col
        cols="2"
      >
        <b-form-group
          label="1º horário"
          label-for="medication"
        >

          <validation-provider
            #default="{ errors }"
            name="1º horário"
            rules="required"
          >

            <b-form-input
              v-model="time[0]"
              type="time"
              @blur="nextTimes(false)|save()"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

        </b-form-group>

      </b-col>
      <b-col
        cols="3"
      >
        <b-form-group
          label="Quantidade"
          label-for="medication"
        >

          <validation-provider
            #default="{ errors }"
            name="quantidade"
            rules="required|decimal"
          >

            <!-- <b-input-group :append="lower(medication.medication_format)"> -->

            <b-form-input
              v-model="amount[0]"
              type="number"
              @blur="save()"
            />

            <!-- </b-input-group> -->

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

        </b-form-group>

      </b-col>
    </b-row>
    <div
      v-for="index in selected.time"
      :key="index"
    >
      <b-row v-if="renderComponent">
        <b-col
          cols="4"
        />
        <b-col
          cols="2"
        >
          <b-form-group
            :label="index + 1 +'º horário'"
            :label-for="'medication_' + index + 1"
          >

            <b-form-input
              v-model="time[index]"
              type="time"
              disabled
            />

          </b-form-group>

        </b-col>
        <b-col
          cols="3"
        >
          <b-form-group
            label="Quantidade"
            :label-for="'medication_amount_' + index + 1"
          >

            <validation-provider
              #default="{ errors }"
              name="quantidade"
              rules="required|decimal"
            >

              <!-- <b-input-group :append="lower(medication.medication_format)"> -->
              <b-form-input
                v-model="amount[index]"
                @blur="save()"
              />
              <!-- </b-input-group> -->

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, extend } from 'vee-validate'
import {
  BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // BInputGroup,

    vSelect,

    ValidationProvider,

  },
  directives: {
    Ripple,
  },

  data() {
    return {
      time: [],
      amount: [],
      selected: '',
      renderComponent: true,
      medication: [],
      intervalOptions: [
        { id: 1, name: '1x ao dia (de 24 em 24 horas)', time: 0 },
        { id: 2, name: '2x ao dia (de 12 em 12 horas)', time: 1 },
        { id: 3, name: '3x ao dia (de 8 em 8 horas)', time: 2 },
        { id: 4, name: '4x ao dia (de 6 em 6 horas)', time: 3 },
        { id: 5, name: '6x ao dia (de 4 em 4 horas)', time: 5 },
        { id: 6, name: '12x ao dia (de 2 em 2 horas)', time: 11 },
        { id: 7, name: '24x ao dia (de 1 em 1 hora)', time: 23 },
      ],
    }
  },

  mounted() {
    this.medication = this.$store.getters['formMedication/medication']
    this.selected = this.$store.getters['formMedication/timeInterval']
    this.time = this.$store.getters['formMedication/timeTimeFixed']
    this.amount = this.$store.getters['formMedication/timeAmountFixed']

    extend('decimal', value => {
      const rest = value % 0.25
      if (rest === 0) {
        return true
      }
      return 'O valor decimal deverá ser: 00, 25, 50 ou 75'
    })
  },

  methods: {
    nextTimes(value) {
      if (this.time.length !== 0) {
        if (value) {
          this.time.length = 1
        }
        if (this.time[0].length === 5 && this.selected !== '') {
          if (this.selected.time !== 0) {
            this.renderComponent = false
            const times = this.selected.time + 1
            let time = this.time[0]
            for (let j = 1; j <= times - 1; j += 1) {
              const hours = 24 / times
              time = moment(time, 'HH:mm').add(hours, 'hours').format('HH:mm')
              this.time[j] = time
            }
          }
          this.renderComponent = true
        }
      }
    },
    nextAmount(value) {
      if (this.amount.length !== 0) {
        if (value) {
          this.amount = []
        }
        if (this.amount[0].length !== '' && this.selected !== '') {
          if (this.selected.time !== 0) {
            this.renderComponent = false
            const times = this.selected.time
            const amount = this.amount[0]
            for (let j = 1; j <= times; j += 1) {
              if (this.amount[j] === undefined) {
                this.amount[j] = amount
              }
            }
          }
          this.renderComponent = true
        }
      }
    },
    save() {
      this.$store.commit('formMedication/UPDATE_TIME_INTERVAL', this.selected)
      this.$store.commit('formMedication/UPDATE_TIME_TIME_FIXED', this.time)
      this.$store.commit('formMedication/UPDATE_TIME_AMOUNT_FIXED', this.amount)
      this.$store.commit('patient/UPDATE_FAVORITE_FORM', false)
    },
    lower(value) {
      if (value !== undefined) {
        return value.toLowerCase()
      }
      return ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
