var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-1"},[_c('validation-observer',{ref:"simpleRules"},[_c('b-row',[_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":"1º horário","label-for":"medication"}},[_c('validation-provider',{attrs:{"name":"1º horário","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"time"},on:{"blur":function($event){return _vm.save()}},model:{value:(_vm.time[0]),callback:function ($$v) {_vm.$set(_vm.time, 0, $$v)},expression:"time[0]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Quantidade","label-for":"medication"}},[_c('validation-provider',{attrs:{"name":"quantidade","rules":"required|decimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","step":"0.25"},on:{"blur":function($event){return _vm.save()}},model:{value:(_vm.amount[0]),callback:function ($$v) {_vm.$set(_vm.amount, 0, $$v)},expression:"amount[0]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_vm._l((_vm.times),function(index){return _c('div',{key:index},[(_vm.renderComponent)?_c('b-row',[_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":index + 1 +'º horário',"label-for":'medication_' + index + 1}},[_c('validation-provider',{attrs:{"name":index + 1 +'º horário',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"time"},on:{"blur":function($event){return _vm.save()}},model:{value:(_vm.time[index]),callback:function ($$v) {_vm.$set(_vm.time, index, $$v)},expression:"time[index]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Quantidade","label-for":"medication"}},[_c('validation-provider',{attrs:{"name":"quantidade","rules":"required|decimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","step":"0.25"},on:{"blur":function($event){return _vm.save()}},model:{value:(_vm.amount[index]),callback:function ($$v) {_vm.$set(_vm.amount, index, $$v)},expression:"amount[index]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-button',{staticClass:"mt-2 mb-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){_vm.delTime(index)|_vm.save()}}},[_vm._v(" Excluir ")])],1)],1):_vm._e()],1)}),_c('b-button',{staticClass:"mt-2 mb-2",attrs:{"variant":"primary"},on:{"click":_vm.addTime}},[_vm._v(" Adicionar novo horário ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }