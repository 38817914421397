<template>
  <div class="m-1">
    <validation-observer ref="simpleRules">
      <b-row>
        <b-col
          cols="2"
        >
          <b-form-group
            label="1º horário"
            label-for="medication"
          >

            <validation-provider
              #default="{ errors }"
              name="1º horário"
              rules="required"
            >

              <b-form-input
                v-model="time[0]"
                type="time"
                @blur="save()"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-form-group>

        </b-col>
        <b-col
          cols="3"
        >
          <b-form-group
            label="Quantidade"
            label-for="medication"
          >

            <validation-provider
              #default="{ errors }"
              name="quantidade"
              rules="required|decimal"
            >

              <!-- <b-input-group :append="medication.medication_format.toLowerCase()"> -->
              <b-form-input
                v-model="amount[0]"
                type="number"
                step="0.25"
                @blur="save()"
              />
              <!-- </b-input-group> -->

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-form-group>
        </b-col>
      </b-row>
      <div
        v-for="index in times"
        :key="index"
      >
        <b-row v-if="renderComponent">
          <b-col
            cols="2"
          >
            <b-form-group
              :label="index + 1 +'º horário'"
              :label-for="'medication_' + index + 1"
            >

              <validation-provider
                #default="{ errors }"
                :name="index + 1 +'º horário'"
                rules="required"
              >

                <b-form-input
                  v-model="time[index]"
                  type="time"
                  @blur="save()"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>

          </b-col>
          <b-col
            cols="3"
          >
            <b-form-group
              label="Quantidade"
              label-for="medication"
            >

              <validation-provider
                #default="{ errors }"
                name="quantidade"
                rules="required|decimal"
              >

                <!-- <b-input-group :append="medication.medication_format.toLowerCase()"> -->
                <b-form-input
                  v-model="amount[index]"
                  type="number"
                  step="0.25"
                  @blur="save()"
                />
                <!-- </b-input-group> -->

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>
          </b-col>
          <b-col
            cols="2"
          >
            <b-button
              variant="outline-danger"
              class="mt-2 mb-2"
              @click="delTime(index)|save()"
            >
              Excluir
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-button
        variant="primary"
        class="mt-2 mb-2"
        @click="addTime"
      >
        Adicionar novo horário
      </b-button>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  BRow, BCol, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    // BInputGroup,

    ValidationProvider,
    ValidationObserver,

  },
  directives: {
    Ripple,
  },

  data() {
    return {
      time: [],
      amount: [],
      times: 0,
      medication: [],
    }
  },

  mounted() {
    this.medication = this.$store.getters['formMedication/medication']
    this.time = this.$store.getters['formMedication/timeTimeFree']
    this.amount = this.$store.getters['formMedication/timeAmountFree']
    this.reloadTimes()

    extend('decimal', value => {
      const rest = value % 0.25
      if (rest === 0) {
        return true
      }
      return 'O valor decimal deverá ser: 00, 25, 50 ou 75'
    })
  },

  methods: {
    reloadTimes() {
      this.renderComponent = false
      if (this.time.length > 0) {
        this.times = this.time.length - 1
      } else {
        this.times = 0
      }
      this.renderComponent = true
    },
    addTime() {
      this.renderComponent = true
      this.times += 1
    },
    delTime(value) {
      this.renderComponent = false

      this.time.splice(value, 1)
      this.times -= 1

      this.amount.splice(value, 1)

      this.renderComponent = true
    },
    save() {
      this.$store.commit('formMedication/UPDATE_TIME_INTERVAL', '')
      this.$store.commit('formMedication/UPDATE_TIME_TIME_FREE', this.time)
      this.$store.commit('formMedication/UPDATE_TIME_AMOUNT_FREE', this.amount)
      this.$store.commit('patient/UPDATE_FAVORITE_FORM', false)
    },
  },

}
</script>
