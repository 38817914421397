<template>

  <div>
    <b-card
      no-body
      class="mb-2 pb-2 pl-2 pr-2"
    >
      <h4 class="mt-2 pt-2">
        {{ this.$store.getters['formMedication/action'] }}
      </h4>
      <div class="mt-2 d-flex justify-content-start breadcrumb-wrapper">
        <b-breadcrumb :items="items" />
      </div>
      <validation-observer ref="timeRules">
        <b-form>
          <b-row>

            <b-col
              cols="12"
            >
              <b-form-group
                class="mt-2"
              >
                Horário

                <b-form-radio
                  v-model="selectedTime"
                  class="mt-1"
                  value="Intervalos fixos (8 em 8h, 6 em 6h…)"
                  @input="clear()"
                >
                  Intervalos fixos (8 em 8h, 6 em 6h…)
                </b-form-radio>

                <form-time-fixed v-if="selectedTime === 'Intervalos fixos (8 em 8h, 6 em 6h…)'" />

                <b-form-radio
                  v-model="selectedTime"
                  class="mt-1"
                  value="Horários livres"
                  @input="clear()"
                >
                  Horários livres
                </b-form-radio>

                <form-time-free v-if="selectedTime === 'Horários livres'" />

                <template v-if="link === 'favorite'">
                  <b-form-radio
                    v-model="selectedTime"
                    class="mt-1"
                    value="Não selecionar um horário agora"
                  >
                    Não selecionar um horário agora
                  </b-form-radio>
                </template>

              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>

      <b-col
        cols="12"
        class="mt-2"
      >
        <b-button
          type="button"
          variant="outline-primary"
          @click="cancel"
        >
          Cancelar
        </b-button>
        <b-button
          type="button"
          variant="outline-primary"
          class="ml-2"
          @click="previous"
        >
          Voltar
        </b-button>
        <b-button
          type="submit"
          variant="primary"
          class="ml-2"
          @click="next"
        >
          Continuar
        </b-button>
      </b-col>
    </b-card>
    <form-abstract />
  </div>

</template>

<script>
import { ValidationObserver } from 'vee-validate'
import {
  BCard, BBreadcrumb, BForm, BRow, BCol, BFormGroup, BButton, BFormRadio,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import formAbstract from './Form-medication-abstract.vue'
import formTimeFixed from './Form-medication-time-fixed.vue'
import formTimeFree from './Form-medication-time-free.vue'

export default {
  components: {
    BCard,
    BBreadcrumb,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormRadio,

    formAbstract,
    formTimeFixed,
    formTimeFree,

    ValidationObserver,

  },
  directives: {
    Ripple,
  },

  data() {
    return {
      link: '',
      selectedTime: '',
      showItem: true,
      favoriteForm: this.$store.getters['patient/favoriteForm'],
      items: [
        {
          text: 'Medicamento',
          active: true,
        },
        {
          text: 'Horário',
          active: false,
        },
        {
          text: 'Frequência',
          active: true,
        },
        {
          text: 'Duração',
          active: true,
        },
        {
          text: 'Resumo',
          active: true,
        },
      ],
    }
  },

  watch: {
    // eslint-disable-next-line func-names
    '$store.state.patient.favoriteForm': function () {
      this.favoriteForm = this.$store.getters['patient/favoriteForm']
    },
  },

  mounted() {
    this.selectedTime = this.$store.getters['formMedication/time']
    this.link = this.$store.getters['formMedication/link']
  },

  methods: {
    next() {
      this.$refs.timeRules.validate().then(success => {
        if (success) {
          this.$store.commit('formMedication/UPDATE_SHOW_TIME', true)
          this.$store.commit('formMedication/UPDATE_TIME', this.selectedTime)

          let time
          let amount

          if (this.selectedTime === 'Intervalos fixos (8 em 8h, 6 em 6h…)') {
            time = this.$store.getters['formMedication/timeTimeFixed']
            amount = this.$store.getters['formMedication/timeAmountFixed']
          } else if (this.selectedTime === 'Horários livres') {
            time = this.$store.getters['formMedication/timeTimeFree']
            amount = this.$store.getters['formMedication/timeAmountFree']
          } else {
            time = ''
            amount = ''
          }

          this.$store.commit('formMedication/UPDATE_TIME_TIME', time)
          this.$store.commit('formMedication/UPDATE_TIME_AMOUNT', amount)

          this.$store.commit('patient/UPDATE_FAVORITE_FORM', false)

          this.$router.push(`${this.link}-frequency`)
        }
      })
    },
    cancel() {
      if (this.link === 'patient') {
        this.$store.commit('patient/UPDATE_SHOW_TAB', true)
        this.$store.commit('patient/UPDATE_SHOW_REMINDER_ADD', false)
        this.$store.commit('patient/UPDATE_SHOW_REMINDER_FAVORITE', true)
        this.$router.push(`${this.link}-edit`)
      } else {
        this.$router.push(this.link)
      }
    },
    previous() {
      this.$router.push(`${this.link}-medication`)
    },
    clear() {
      this.showItem = false
      if (this.favoriteForm === false) {
        this.$store.commit('formMedication/UPDATE_TIME_INTERVAL', [])
        this.$store.commit('formMedication/UPDATE_TIME_TIME_FIXED', [])
        this.$store.commit('formMedication/UPDATE_TIME_TIME_FREE', [])
        this.$store.commit('formMedication/UPDATE_TIME_AMOUNT_FIXED', [])
        this.$store.commit('formMedication/UPDATE_TIME_AMOUNT_FREE', [])
      }
      this.showItem = true
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
